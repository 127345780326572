import React, { useState } from 'react';
import axios from 'axios';
import {
  Container, Paper, TextField, Button, Typography, Box, CircularProgress, Grid, List, ListItem, ListItemText
} from '@mui/material';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './App.css';

const App = () => {
  const [title, setTitle] = useState('');
  const [aiAnalysis, setAiAnalysis] = useState(null);
  const [loading, setLoading] = useState(false);

  const analyzeTitle = async () => {
    setLoading(true);
    try {
      const aiResponse = await axios.post('/api/ai-analyze', { title });
      console.log("AI Analysis Response:", aiResponse.data.ai_analysis);
      setAiAnalysis(aiResponse.data.ai_analysis);
    } catch (error) {
      console.error("There was an error analyzing the title!", error);
    } finally {
      setLoading(false);
    }
  };

  const getScoreColor = (score) => {
    if (score >= 70) {
      return '#00C853';  // verde
    } else if (score >= 40) {
      return '#FFD600';  // amarelo
    } else {
      return '#D50000';  // vermelho
    }
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom align="center">
        AI Headline Analyser
      </Typography>
      <Paper sx={{ padding: 3, marginBottom: 3 }}>
        <TextField
          fullWidth
          label="Enter headline here"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          variant="outlined"
        />
        <Button fullWidth variant="contained" color="primary" onClick={analyzeTitle} disabled={loading} sx={{ marginTop: 2 }}>
          Analyze
        </Button>
      </Paper>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
          <Typography variant="h6" component="p" sx={{ marginLeft: 2 }}>
            Analyzing headline... Please wait.
          </Typography>
        </Box>
      ) : (
        aiAnalysis && (
          <Box sx={{ marginTop: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Paper sx={{ padding: 2, textAlign: 'center', backgroundColor: '#E3F2FD' }}>
                  <Typography variant="h6" sx={{ marginBottom: 2 }}>
                    Overall Headline Quality Score
                  </Typography>
                  <Box sx={{ width: 150, height: 150, margin: '0 auto' }}>
                    <CircularProgressbar
                      value={aiAnalysis.score}
                      text={`${aiAnalysis.score}`}
                      styles={buildStyles({
                        pathColor: getScoreColor(aiAnalysis.score),
                        textColor: getScoreColor(aiAnalysis.score),
                        trailColor: '#d6d6d6',
                      })}
                    />
                  </Box>
                  <Typography variant="body1" sx={{ marginTop: 2 }}>
                    {aiAnalysis.score >= 70 ? 'Excellent' : aiAnalysis.score >= 40 ? 'Average' : 'Below Average'}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} md={8}>
                <Paper sx={{ padding: 2 }}>
                  <Typography variant="h6" component="h3" sx={{ marginBottom: 2 }}>
                    Detailed Analysis
                  </Typography>
                  <Grid container spacing={2}>
                    {aiAnalysis.analysis.map((item, index) => (
                      <Grid item xs={12} key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 80, height: 80, marginRight: 2 }}>
                          <CircularProgressbar
                            value={item.score}
                            text={`${item.score}`}
                            styles={buildStyles({
                              pathColor: getScoreColor(item.score),
                              textColor: getScoreColor(item.score),
                              trailColor: '#d6d6d6',
                            })}
                          />
                        </Box>
                        <Box>
                          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                            {item.criterion}
                          </Typography>
                          <Typography variant="body2">
                            {item.description}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Paper sx={{ padding: 2, marginTop: 3 }}>
              <Typography variant="h6" component="h3" sx={{ marginBottom: 2 }}>
                Alternative Headlines
              </Typography>
              <List>
                {aiAnalysis.alternatives.map((alternative, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={`${index + 1}. ${alternative.headline}`} />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Box>
        )
      )}
    </Container>
  );
};

export default App;


































